import React from "react";


const customHeader = () => {
    return (
        <header>
            <nav className="navbar navbar-expand-lg navbar-light  bg-body-secondary border-bottom">
                <div className="container-fluid">
                    <a className="company-name-in-nav navbar-brand p-0 ps-3" href="#">
                        <span className="first-name">EASE</span><span className="ms-2">LETTER</span>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <a className="nav-link nav-routs" href="#">HOME</a>
                            </li>
                            
                            <li className="nav-item">
                                <a className="nav-link nav-routs" href="https://easeletter.com/blog/" onClick={()=>alert("We are working on it. This will be live soon..")} >BLOG</a>
                            </li>
                          
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}
export default customHeader;