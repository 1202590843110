import React, { useEffect, useState } from "react";
import moment from 'moment';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { MdOutlineRefresh } from "react-icons/md";
import { GrDocumentPdf } from "react-icons/gr";
const FormAndOverView = () => {
    const PaginationData = [
        {
            Title: "Basic Information",
            Id: 0,
            IsSelected: true,
            Information: {
                DateOfIssue: "",
                JoiningDate: "",
                EndDate: "",
            }
        },
        {
            Title: "Employee Details",
            Id: 1,
            IsSelected: false,
            Information: {
                Name: "",
                Gender: "",
                JopProfile: "",
                Department: "",
            }
        },
        {
            Title: "Company Details",
            Id: 2,
            IsSelected: false,
            Information: {
                Logo: "",
                CompanyName: "",
                Address: "",
                Email: "",
                Phone: "",
                NameOfAuthority: "",
                PostOfAuthority: "",
            }
        },
    ]
    const [refreshCount, setRefreshCount] = useState(0);
    const [navigationData, setNavigationData] = useState(PaginationData);
    const [indexCount, setIndexCount] = useState(0);
    const [ViewData, setViewData] = useState(false);

    function handleChangeComment(e) {
        let { name, type, id, value } = e.target;
        const copy = navigationData[id].Information
        if (name == "Name") {
            let valueInput = value?.toLowerCase();
            let firstLatter = valueInput[0];
            let CapFirst = firstLatter.toUpperCase();
            let RestText = valueInput.slice(1, valueInput.length);
            value = CapFirst + RestText;
        }
        if (name == "Logo") {
            console.log(e.target.files);
            let ImgURL = URL.createObjectURL(e.target.files[0]);
            value = ImgURL;
        }
        const obj = { ...copy, [name]: value }
        navigationData[id].Information = obj;
        console.log(navigationData)
    }

    const validationFunction = (CurrentIndex, Type) => {
        if (Type == "Next" || Type == "View") {
            let checkForValue = false;
            if (navigationData?.length > 0) {
                navigationData?.map((Item, Index) => {
                    if (Index === 0 && Index === CurrentIndex - 1) {
                        if (Item.Information.EndDate?.length > 0 && Item.Information.JoiningDate?.length > 0 && Item.Information.EndDate?.length > 0) {
                            checkForValue = false;
                        } else {
                            checkForValue = true;
                        }
                    }
                    if (Index === 1 && Index === CurrentIndex - 1) {
                        if (Item.Information.Name?.length > 0 && Item.Information.Gender?.length > 0 && Item.Information.JopProfile?.length > 0) {
                            checkForValue = false;
                        } else {
                            checkForValue = true;
                        }
                    }
                    if (Index === 2 && Index === CurrentIndex - 1) {
                        if (Item.Information.CompanyName?.length > 0 && Item.Information.Email?.length > 0 && Item.Information.Phone?.length > 0 && Item.Information.Address?.length > 0) {
                            checkForValue = false;
                        } else {
                            checkForValue = true;
                        }
                    }
                })
            }
            if (!checkForValue) {
                if (Type == "Next") {
                    setIndexCount(CurrentIndex)
                }
                if (Type == "View") {
                    setViewData(true);
                    console.log("Print All Data", navigationData)
                }
            } else {
                alert("Please fill all Required Information");
            }
        }
        if (Type == "Previous") {
            setIndexCount(CurrentIndex)
        }

    }

    const printDocument = () => {
        const input = document.getElementById('print-pdf');
        const promise = new Promise((resolve) => {
            html2canvas(input)
                .then((canvas) => {
                    resolve(canvas);
                });
        });

        promise.then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            pdf.addImage(imgData, 'JPEG', 0, 0, pdf.internal.pageSize.width, 0);
            pdf.save("Experience-Letter.pdf");
            alert("Your Experience Letter Is Generated Go Download Manager")
        });
    };


    const RefreshPage = () => {
        setNavigationData([...PaginationData]);
        setViewData(false);
        setIndexCount(0)
    }


    return (
        <div className="d-flex bg-body-secondary">
            <div className="col-5 form-container p-5 mx-5 pb-5">
                <div className=""
                    onChange={handleChangeComment} >
                    <ul className="nav nav-underline mb-2">
                        {navigationData?.map((Item, Index) => {
                            return (
                                <li key={Item.Id} className={"nav-item"}>
                                    <a className={indexCount == Index ? "nav-link content-pointer contentColor active" : "nav-link contentColor content-pointer"} onClick={() => setIndexCount(Index)} aria-current="page">{Item.Title}</a>
                                </li>
                            )
                        })}
                    </ul>
                    {navigationData?.map((Item, Index) => {
                        if (indexCount == 0 && Index == indexCount) {
                            return (
                                <div key={Item.Id}>
                                    <div className="contentColor">
                                        <div className="mb-2">
                                            <label className="form-label contentColor mb-1">Date Of Letter Issue*</label>
                                            <input type="date"
                                                name={"DateOfIssue"}
                                                className="form-control rounded-0"
                                                id={Index}
                                                defaultValue={Item.Information.DateOfIssue}
                                                placeholder="Enter date-of-issue"
                                                required
                                            />

                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label contentColor mb-1">Date Of Joining*</label>
                                            <input
                                                type="date"
                                                name={"JoiningDate"}
                                                className="form-control rounded-0"
                                                id={Index}
                                                defaultValue={Item.Information.JoiningDate}
                                                placeholder="Enter date-of-joining"
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label contentColor mb-1">Date Of End*</label>
                                            <input
                                                type="date"
                                                name={"EndDate"}
                                                className="form-control rounded-0"
                                                id={Index}
                                                defaultValue={Item.Information.EndDate}
                                                placeholder="Enter date-of-end"
                                                required />
                                        </div>
                                        <button className="p-1 px-4 float-end submit-btn my-2" onClick={() => validationFunction(1, "Next")}>Next</button>
                                    </div>
                                </div>
                            )
                        }
                        if (indexCount == 1 && Index == indexCount) {
                            return (
                                <div key={Item.Id}>
                                    <div className="contentColor">
                                        <div className="mb-2">
                                            <label className="form-label contentColor mb-1">Name*</label>
                                            <input
                                                type="text"
                                                name={'Name'}
                                                className="form-control rounded-0"
                                                id={Index}
                                                placeholder="Enter Employee Name"
                                                defaultValue={Item.Information.Name}
                                                required
                                            />

                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label contentColor mb-1">Gender*</label>
                                            <select className="form-select rounded-0"
                                                name={"Gender"}
                                                id={Index}
                                                aria-label="Default select example"
                                                defaultValue={Item.Information.Gender}
                                                required
                                            >
                                                <option selected>Select Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Transgender">Transgender</option>
                                            </select>
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label contentColor mb-1">Job Profile*</label>
                                            <input
                                                type="text"
                                                name={"JopProfile"}
                                                className="form-control rounded-0"
                                                id={Index}
                                                placeholder="Enter Job Profile"
                                                defaultValue={Item.Information.JopProfile}
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label contentColor mb-1">Department / Team</label>
                                            <input
                                                type="text"
                                                name={"Department"}
                                                className="form-control rounded-0"
                                                id={Index}
                                                placeholder="Enter Department / Team"
                                                defaultValue={Item.Information.Department}
                                            />
                                        </div>
                                        <button className="p-1 px-4 float-end submit-btn my-2" onClick={() => validationFunction(2, "Next")}>Next</button>
                                        <button className="p-1 px-2 float-end submit-btn my-2 me-2" onClick={() => validationFunction(0, "Previous")}>Previous</button>
                                    </div>
                                </div>
                            )
                        }
                        if (indexCount == 2 && Index == indexCount) {
                            return (
                                <div key={Item.Id}>
                                    <div className="contentColor">
                                        <div className="mb-2">
                                            <label className="form-label contentColor mb-1">Company Logo</label>
                                            <input
                                                type="file"
                                                className="form-control rounded-0"
                                                id={Index}
                                                name="Logo"
                                                defaultValue={Item.Information.Logo}
                                                placeholder="Attach Company Logo"

                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label contentColor mb-1">Company Name*</label>
                                            <input
                                                type="text"
                                                className="form-control rounded-0"
                                                id={Index}
                                                name="CompanyName"
                                                placeholder="Enter Company Name"
                                                defaultValue={Item.Information.CompanyName}
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label contentColor mb-1">Email*</label>
                                            <input
                                                type="text"
                                                className="form-control rounded-0"
                                                id={Index}
                                                name="Email"
                                                defaultValue={Item.Information.Email}
                                                placeholder="Enter Email Address"
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label contentColor mb-1">Phone*</label>
                                            <input
                                                type="Number"
                                                className="form-control rounded-0"
                                                id={Index}
                                                name="Phone"
                                                defaultValue={Item.Information.Phone}
                                                placeholder="Enter Phone"
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label contentColor mb-1">Name Of Authority</label>
                                            <input
                                                rows={1}
                                                type='text'
                                                className="form-control rounded-0"
                                                id={Index}
                                                name="NameOfAuthority"
                                                defaultValue={Item.Information.NameOfAuthority}
                                                placeholder="Enter Details Of Authority"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label contentColor mb-1">Designation Of Authority</label>
                                            <input
                                                rows={1}
                                                type='text'
                                                className="form-control rounded-0"
                                                id={Index}
                                                name="PostOfAuthority"
                                                defaultValue={Item.Information.PostOfAuthority}
                                                placeholder="Enter Details Of Authority"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label contentColor mb-1">Company Address*</label>
                                            <textarea
                                                rows={1}
                                                type="text"
                                                className="form-control rounded-0"
                                                id={Index}
                                                name="Address"
                                                defaultValue={Item.Information.Address}
                                                placeholder="Enter Company Address"
                                                required
                                            />
                                        </div>
                                        <button className="p-1 px-4 float-end submit-btn my-2" onClick={() => validationFunction(3, "View")}>View</button>
                                        <button className="p-1 px-2 float-end submit-btn my-2 me-2" onClick={() => validationFunction(1, "Previous")}>Previous</button>
                                    </div>
                                </div>
                            )
                        }
                    })
                    }
                </div>
            </div>
            <div className="over-view-container p-4 w-100 h-100">
                <div className={ViewData ? "bg-white container print-pdf p-5" : "bg-white print-pdf container p-5 container-blur"} id="print-pdf">
                    <div className="border-bottom d-flex">
                        <div className="col-sm-3">
                            {navigationData[2].Information.Logo ?
                                <div>
                                    <img className="company-logo img-fluid" src={navigationData[2].Information.Logo} />
                                </div> :
                                null
                            }
                        </div>
                        <div className="experience-letter-header-content col-sm-9 text-end">
                            <h5 className="company-name ">{navigationData[2].Information.CompanyName}</h5>
                            <p className="mb-0 ">
                                <span className="mx-2 ">{navigationData[2].Information.Phone},</span>
                                <span className="">{navigationData[2].Information.Email}</span>
                            </p>
                            <span className="company-name ">{navigationData[2].Information.Address}</span>
                            <p className="company-name ">{moment(navigationData[0].Information.DateOfIssue).format('ll')}</p>
                        </div>
                    </div>
                    <div className="experience-letter-Tile">
                        <h3 className="text-center my-3">Experience Letter</h3>
                    </div>
                    <div>
                        <p className="my-3 mt-5">Dear {navigationData[1].Information.Name},</p>
                        <p>
                            It is to certify that <b>{navigationData[1].Information.Name}</b> was employed as a <b>{navigationData[1].Information.JopProfile}</b> in the <b>{navigationData[1].Information.Department}</b> department of <b>{navigationData[2].Information.CompanyName}</b>. from <b>{moment(navigationData[0].Information.JoiningDate).format('ll')}</b> to <b>{moment(navigationData[0].Information.EndDate).format('ll')}</b>.
                        </p>
                        <p>
                            We are sure {navigationData[1].Information.Gender === "Male" ? "his" : "her"} passion and dedication will help {navigationData[1].Information.Gender === "Male" ? "him" : "her"} excel in whatever {navigationData[1].Information.Gender === "Male" ? "he" : "she"} chooses to do next in their life. {navigationData[1].Information.Gender === "Male" ? "He" : "She"} has shown a high level of commitment throughout {navigationData[1].Information.Gender === "Male" ? "his" : "her"} time with our company.
                        </p>
                        <p>
                            We wish <b>{navigationData[1].Information.Name}</b> all the best for their future.
                        </p>
                        <p className="mt-5">
                            Sincerely,
                        </p>
                        <p className="mb-1">
                            {navigationData[2].Information.NameOfAuthority}
                        </p>
                        <p className="mb-1">
                            {navigationData[2].Information.PostOfAuthority}
                        </p>
                        <p className="mb-1">
                            {navigationData[2].Information.CompanyName}
                        </p>
                    </div>
                </div>
                {ViewData &&
                    <footer className="text-end my-3">
                        <button className="mx-2 p-1 px-3 submit-btn" title="Re-Generate" onClick={RefreshPage}><MdOutlineRefresh /> Re-Generate</button>
                        <button className="submit-btn  p-1 px-3" title="Download PDF" onClick={printDocument}><GrDocumentPdf /> Download PDF</button>
                    </footer>}
            </div>
        </div>
    )
}
export default FormAndOverView;