import React from "react";

const FirstSection = (Props) => {
  const BindDataJSON = Props.Data;
  const Position = Props.Position;

  return (
    <div className="bg-body-secondary">
      {BindDataJSON?.length > 0 ? (
        <div className="container pb-5">
          {BindDataJSON?.map((BindData, Index) => {
            return (
              <div
                className={
                  BindData?.ImagePosition !== "Right"
                    ? "d-flex flex-wrap"
                    : "d-flex flex-wrap flex-row-reverse"
                }
                key={Index}
              >
                <div className="col-lg-6 col-md-12 mt-5 p-5">
                  <div className="About-heading">
                    {BindData?.Heading != undefined ? BindData.Heading : ""}
                  </div>
                  <div className="About-subHeading my-1">
                    {BindData?.SubHeading != undefined
                      ? BindData.SubHeading
                      : ""}
                  </div>
                  <div
                    className="my-1"
                    dangerouslySetInnerHTML={{ __html: BindData?.Description }}
                  ></div>
                  {/* {BindData?.Buttons?.length > 0 ?
                        <div>
                            {BindData?.Buttons?.map((BtnItem, BtnIndex) => {
                                return (
                                    <button className="px-5 mt-3 py-2 me-2 submit-btn" style={{ background: `${BtnItem?.BtnColor}`, color: `${BtnItem?.BtnTextColor}` }}>{BtnItem.ButtonName}</button>
                                )
                            })}
                        </div>
                        : ''} */}
                </div>
                <div className="col-lg-6 col-md-12 p-5">
                  <img src={BindData?.ImageURL} className="img-fluid" />
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default FirstSection;
