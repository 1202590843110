import CustomHeader from "./Components/header";
import FormAndOverView from "./Components/formAndOverview";
import FooterSection from './Components/FooterSection.js';
import './App.css'
import FirstSection from "./Components/FirstSection";
import FirstDescriptionImage from './assets/FirstDescriptionImage.png';
import SecondDescriptionImage from './assets/SecondDescriptionImage.png';
function App() {
  const UIContext = [
    {
      Heading: "Ditch the Writer's Block, Craft Beautiful Letters with Ease!",
      SubHeading: "Tired of staring at a blank page when writing essential letters?",
      Description: "Introducing Ease Letter, your friendly online tool that takes the hassle out of letter writing. Generate professional, eye-catching letters in minutes, even if words aren't your friend.",
      Buttons: [
        {
          ButtonName: "View Services",
          BtnColor: "#250a7d",
          BtnTextColor: "#fff",
          Action: "Redirect",
          Redirect: "#"
        },
        {
          ButtonName: "Contact US",
          BtnColor: "White",
          BtnTextColor: "#250a7d",
          Action: "Redirect",
          Redirect: "#"
        }
      ],
      ImageURL: FirstDescriptionImage,
      AboutImage: "This is About the tool",
      ImagePosition: "Left"
    },
    {
      Heading: "With Ease Letter, you can:",
      SubHeading: "",
      Description: `<ul>
      <li><b>Pick your purpose:</b> Experience letter, joining letter, leave application, resignation letter. we've got you covered!</li>
      <li><b>Choose your style:</b> Formal, friendly, creative - find the perfect tone for your message.</li>
      <li><b>Fill in the blanks: </b> Easily customize pre-written templates with your details.</li>
      <li><b>Personalize to perfection: </b>Add logos, colors, and images to make it your own.</li>
      <li><b>Download and print: </b>Get your ready-to-go letter in seconds!</li>
    </ul>`,
      Buttons: [],
      ImageURL: SecondDescriptionImage,
      AboutImage: "This is About the tool",
      ImagePosition: "Right"
    }
  ]

  return (
    <div>
      <CustomHeader />
      <FormAndOverView />
      <FirstSection Data={UIContext} />
      <FooterSection />
    </div>
  );
}

export default App;
